// import { mdiHomeOutline } from '@mdi/js'

export default [
  // {
  //   name: '控制台首页',
  //   icon: 'mdiHomeOutline',
  //   badge: '3',
  //   badgeColor: 'warning',
  //   children: [
  //     {
  //       name: '我的一天',
  //       route: 'dashboard-today',
  //     },
  //     {
  //       name: '紧急任务',
  //       route: 'dashboard-urgent',
  //     },
  //     {
  //       name: '今日统计',
  //       route: 'dashboard-statistics',
  //     },
  //   ],
  // },
]
