// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => {
    if (response.data.status === 401) {
      localStorage.removeItem('accessToken')
      window.location.href = '/login'
    }

    return response
  },
  error => {
    /* 删除"系统内部异常"字样 */
    // eslint-disable-next-line no-param-reassign,no-unused-expressions
    error.response.data.message.includes('系统内部异常') && (error.response.data.message = error.response.data.message.slice(6))

    return Promise.reject(error)
  },

  // error => {
  //   // const { config, response: { status } } = error
  //   const { config, response } = error
  //   const originalRequest = config
  //
  //   // if (status === 401) {
  //   if (response && response.status === 400) {
  //     if (!this.isAlreadyFetchingAccessToken) {
  //       this.isAlreadyFetchingAccessToken = true
  //       this.refreshToken().then(r => {
  //         this.isAlreadyFetchingAccessToken = false
  //
  //         // Update accessToken in localStorage
  //         this.setToken(r.data.accessToken)
  //         this.setRefreshToken(r.data.refreshToken)
  //
  //         this.onAccessTokenFetched(r.data.accessToken)
  //       })
  //     }
  //     const retryOriginalRequest = new Promise(resolve => {
  //       this.addSubscriber(accessToken => {
  //         // Make sure to assign accessToken according to your response.
  //         // Check: https://pixinvent.ticksy.com/ticket/2413870
  //         // Change Authorization header
  //         originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
  //         resolve(this.axiosIns(originalRequest))
  //       })
  //     })
  //
  //     return retryOriginalRequest
  //   }
  //
  //   return Promise.reject(error)
  // },
)

Vue.prototype.$http = axiosIns

export default axiosIns
