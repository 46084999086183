import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"22","elevation":_vm.$vuetify.theme.dark ? 9 : 8,"content-class":"list-style notification-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiBellOutline)+" ")])]}}])},[_c(VCard,{staticClass:"app-bar-notification-content-container"},[_c('perfect-scrollbar',{staticClass:"ps-user-notifications",attrs:{"options":_vm.perfectScrollbarOptions}},[_c(VList,{staticClass:"py-0"},[_c(VListItem,{staticClass:"d-flex",attrs:{"link":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("消息通知")]),_c(VChip,{staticClass:"v-chip-light-bg primary--text font-weight-semibold",attrs:{"small":""}},[_vm._v(" 8 new ")])],1)]),_c(VDivider),_vm._l((_vm.notifications),function(notification,index){return [_c(VListItem,{key:notification.title,attrs:{"link":""}},[_c(VListItemAvatar,{class:[{'v-avatar-light-bg primary--text justify-center': notification.user && !notification.user.avatar}],attrs:{"size":"38"}},[(notification.user && notification.user.avatar)?_c(VImg,{attrs:{"src":notification.user.avatar}}):(notification.user && !notification.user.avatar)?_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.getInitialName(notification.user.name)))]):_c(VImg,{attrs:{"src":notification.service.icon}})],1),_c(VListItemContent,{staticClass:"d-block"},[_c(VListItemTitle,{staticClass:"text-sm font-weight-semibold"},[_vm._v(" "+_vm._s(notification.title)+" ")]),_c(VListItemSubtitle,{staticClass:"text-sm"},[_vm._v(" "+_vm._s(notification.subtitle)+" ")])],1),_c(VListItemAction,[_c('span',{staticClass:"text--secondary text-xs"},[_vm._v(_vm._s(notification.time))])])],1),_c(VDivider,{key:index})]}),_c(VListItem,{key:"read-all-btn",staticClass:"read-all-btn-list-item"},[_c(VBtn,{attrs:{"block":"","color":"primary"}},[_vm._v(" 查看所有消息通知 ")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }