import Swal from 'sweetalert2'

export const toastSuccess = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'success',
    title: message,
  })
}
export const toastInfo = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'info',
    title: message,
  })
}
export const toastFail = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'error',
    title: message,
  })
}

export const modalSuccess = message => Swal.fire(
  '成功!',
  message,
  'success',
)
export const modalFail = message => Swal.fire(
  '失败!',
  message || '未知错误，请联系管理员.',
  'error',
)

export const modalInfo = (message, title) => Swal.fire(
  title || '注意!',
  message,
  'info',
)

export const modalEnquire = (message, title) => Swal.fire({
  title: title || '请确认所填信息无误',
  text: message,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: '是的，我意已决!',
  cancelButtonText: '不，让我再想想',
})

export const modalEnquireTitle = (title, message) => Swal.fire({
  title,
  text: message,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: '是的，我意已决!',
  cancelButtonText: '不，让我再想想',
})

export const modalEnquireBtn = (title, text, confirmButtonText, denyButtonText, cancelButtonText) => Swal.fire({
  title,
  text,
  showDenyButton: !!denyButtonText,
  showCancelButton: !!cancelButtonText,
  confirmButtonText,
  denyButtonText,
  cancelButtonText,
  width: 600,
  padding: '3em',
  color: '#716add',
  background: '#fff url("https://sweetalert2.github.io/images/trees.png")',
  backdrop: `
    rgba(0,0,123,0.4)
    url("https://sweetalert2.github.io/images/nyan-cat.gif")
    left top
    no-repeat
  `,
})
