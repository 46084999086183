// import { toastInfo } from '@core/utils/prompt'
import { modalFail } from '@core/utils/prompt'
import jwtDefaultConfig from './jwtDefaultConfig'

/**
 * 重新登录（清除用户登录信息并刷新页面实现）
 */
// function reloadPage() {
//   localStorage.removeItem('accessToken')
//   localStorage.removeItem('userData')
//   localStorage.removeItem('userAbility')
//   window.location.href = window.location.origin
// }

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosInsParam, jwtOverrideConfig) {
    this.axiosIns = axiosInsParam
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // if (this.getVersion() !== this.jwtConfig.version) {
        //   toastInfo('版本已更新，需要进行重新登陆以更新缓存')
        //
        //   /* 保存版本信息 */
        //   localStorage.setItem(this.jwtConfig.storageVersionKeyName, this.jwtConfig.version)
        //
        //   // 重新登录
        //   // window.location.href = window.location.origin
        //   setTimeout(reloadPage, 2000)
        // }

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${accessToken}`
        }

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (response.data.status === 401) {
          localStorage.removeItem('accessToken')
          window.location.href = '/login'
        }
        if (response.data.status === 403) {
          modalFail('抱歉，您无权限访问！')
          localStorage.clear()
          window.location.href = '/login'
        }

        return response
      },

      // error => {
      //   // const { config, response: { status } } = error
      //   const { config, response } = error
      //   const originalRequest = config
      //
      //   // if (status === 401) {
      //   if (response && response.status === 400) {
      //     if (!this.isAlreadyFetchingAccessToken) {
      //       this.isAlreadyFetchingAccessToken = true
      //       this.refreshToken().then(r => {
      //         this.isAlreadyFetchingAccessToken = false
      //
      //         // Update accessToken in localStorage
      //         this.setToken(r.data.accessToken)
      //         this.setRefreshToken(r.data.refreshToken)
      //
      //         this.onAccessTokenFetched(r.data.accessToken)
      //       })
      //     }
      //     const retryOriginalRequest = new Promise(resolve => {
      //       this.addSubscriber(accessToken => {
      //         // Make sure to assign accessToken according to your response.
      //         // Check: https://pixinvent.ticksy.com/ticket/2413870
      //         // Change Authorization header
      //         originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
      //         resolve(this.axiosIns(originalRequest))
      //       })
      //     })
      //
      //     return retryOriginalRequest
      //   }
      //
      //   return Promise.reject(error)
      // },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getVersion() {
    return localStorage.getItem(this.jwtConfig.storageVersionKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout() {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
  }

  userInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.userInfoEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  isLogin(...args) {
    return this.axiosIns.post(this.jwtConfig.isLoginEndpoint, ...args)
  }

  ssoAuthUrl(...args) {
    return this.axiosIns.post(this.jwtConfig.ssoAuthUrlEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getRoute(...args) {
    return this.axiosIns.get(this.jwtConfig.routeEndpoint, ...args)
  }

  getMenu(...args) {
    return this.axiosIns.get(this.jwtConfig.menuEndpoint, ...args)
  }
}
