const dashboard = [
  {
    path: '/dashboards/today',
    name: 'dashboard-today',
    component: () => import('@/views/dashboards/today/Today.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/urgent',
    name: 'dashboard-urgent',
    component: () => import('@/views/dashboards/urgent/Urgent.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/statistics',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboards/statistics/Statistics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/statistics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboards/statistics/Analytics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/eCommerce',
    name: 'dashboard-eCommerce',
    component: () => import('@/views/dashboards/urgent/Ecommerce.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard
